
					@import './src/sass/variables';
					@import './src/sass/mixins';
				





















































































































































.responsive-table {
	max-width: 100%;
	overflow: auto;

	.collapse-arrow {
		display: none;
	}

	&.collapse table {
		thead {
			display: none;
		}

		.collapse-arrow {
			display: block;
		}

		tr {
			td {
				display: none;
				padding-left: $default_padding * 2;
				overflow: hidden;
				border-width: 0 0 1px;

				&:first-child {
					display: block;
					position: relative;
					background-color: $color__white;
					cursor: pointer;

					svg {
						position: absolute;
						top: 50%;
						left: 15px;
						transform: translateY(-50%) rotate(180deg);
						transition: transform .2s ease;
					}
				}

				&:last-child {
					padding-bottom: .5em;
				}

				& > span {
					margin-left: 5px;
				}

				&:not(.cta):before {
					content: attr(data-th);
					font-weight: bold;
					display: inline-block;
				}
			}

			&.open td {
				display: block;

				&.cta > div {
					justify-content: flex-start;
				}

				&:first-child svg {
					transform: translateY(-50%);
				}
			}
		}
	}

	table {
		min-width: 300px;
		max-width: none;
		width: 100%;
		height: fit-content;
		border-collapse: collapse;

		th,
		td {
			&:first-child {
				padding-left: $site__padding * 2;
			}

			&:last-child {
				padding-right: $site__padding * 2;
			}
		}

		thead {
			text-transform: uppercase;
			letter-spacing: 0.05em;
			font-weight: $font__bold;

			th,
			td {
				background: rgba($color__white, 0.75);
				border-top: $border;
				border-bottom: $border;
			}
		}

		tbody {
			td {
				border-bottom: $border;
				border-right: $border;
				border-left: none;
				border-top: none;
			}

			td::before {
				display: none;
			}

			td:last-child {
				border-right: none;
			}
		}

		tr:nth-child(2n) {
			th,
			td {
				background: rgba($color__white, 0.5);
			}
		}

		.button > * {
			line-height: 1.75em;
			padding: 0 0.75em;
		}
	}
}

.pagination {
	padding: 0 80px;

	ul {
		display: inline-flex;
		flex-flow: row nowrap;
		justify-content: center;
		border-radius: $border_radius;
		border: $border;
		background-color: $color__white;
		padding: 0;
	}

	li {
		line-height: 2em;
		padding: 0 1em;

		&.active {
			font-weight: bold;
		}

		&:not(.active) {
			cursor: pointer;

			&:hover {
				background-color: $color__semi_dark;
			}
		}
	}
}
