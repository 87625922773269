
					@import './src/sass/variables';
					@import './src/sass/mixins';
				
































.button:not(:first-child) {
	margin-left: 10px;
}
